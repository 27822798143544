<template>
    <div>
        <div class="container-geral">
            <template v-for="(modulo, key) in tarefas" :key="key">
                <div class="container-produtos">
                    <div class="corredores">
                        <h2>{{ key }}</h2>
                    </div>
                    <div class="produtos">
                        <div>
                            <div v-for="(tarefa, keyTarefa) in modulo" :key="keyTarefa" class="produto1">
                                <router-link
                                    class="produto-descricao"
                                    :to="{
                                        name: 'produtos.descricao',
                                        params: {
                                            codigo: tarefa.codigo_de_barras,
                                            validade: tarefa.data_validade,
                                        },
                                    }"
                                >
                                    <div class="container-img">
                                        <img
                                            :src="
                                                'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                                                tarefa.codigo_de_barras +
                                                '.webp'
                                            "
                                            @error="replaceByDefault"
                                            :alt="tarefa.descricao"
                                        />
                                    </div>
                                    <div class="nome-validade">
                                        <h2>{{ tarefa.descricao }}</h2>
                                        <div class="single-validade" v-if="verificaNumeroValidades(tarefa)">
                                            <h3 class="validade">
                                                Validades incluídas: <br />
                                                <div class="validades-extra">
                                                    {{ validadesExistentes(tarefa) }}
                                                </div>
                                            </h3>
                                        </div>
                                        <div v-else class="showValida">
                                            <div>
                                                <h3 class="validade">
                                                    Validade:
                                                    {{ dataFormatada(tarefa.validadesAdicionais[0].validade) }}
                                                </h3>
                                                <h3 class="quantidade">
                                                    Quantidade:
                                                    {{ tarefa.validadesAdicionais[0].quantidade }}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <router-link class="btn-add-container" :to="{ name: 'incluirProdutos' }">
                <img src="../assets/img/btn-add.svg" alt="botão para adicionar produtos" />
            </router-link>
        </div>
    </div>
</template>

<script>
import img from '../assets/img/broken-image.svg';

export default {
    data() {
        return {
            tarefas: null,
            produto: null,
        };
    },

    computed: {
        getProdutos() {
            return this.$store.getters['inclusao/getProdutos'];
        },
    },

    created() {
        this.tarefas = this.getProdutos;
    },

    methods: {
        dataFormatada(diaDB) {
            let data = new Date(diaDB + ' 00:00');
            return data.toLocaleDateString();
        },

        replaceByDefault(e) {
            e.target.src = img;
        },

        validadesExistentes(element) {
            if (element.validadesAdicionais) {
                return element.validadesAdicionais.length;
            }
        },

        verificaNumeroValidades(tarefa) {
            return tarefa.validadesAdicionais.length > 1 ? true : false;
        },
    },
};
</script>

<style scoped>
.container-geral {
    margin-top: 66px;
    margin-bottom: 140px;
}

.container-produtos {
    margin: 15px 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px var(--cinza-escuro);
}

.corredores {
    padding: 10px 12px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #bebebe;
}

.corredores h2 {
    text-transform: uppercase;
    color: #707070;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 33ch;
}

.single-validade {
    min-width: 50vw;
}

.produto1 {
    padding: 10px;
    box-shadow: 0 25px 0px -24px #bebebe;
    position: relative;
}

.produto-descricao {
    display: flex;
    gap: 15px;
}

.container-img {
    display: flex;
    place-items: center;
}

.container-img img {
    width: 70px;
    height: 70px;
}

.nome-validade {
    display: block;
}

.nome-validade h2 {
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    max-width: 25ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #484848;
}

.nome-validade h3 {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 28ch;
    color: #484848;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.validade {
    font-weight: 700;
}

.btn-add-container {
    width: 56px;
    height: 56px;
    display: flex;
    place-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--verde);
    position: fixed;
    bottom: 86px;
    left: 76%;
    box-shadow: 0 0 5px #000;
}

.btn-add-container img {
    width: 24px;
    height: 24px;
}

.validades-extra {
    font-size: 14px;
    font-weight: 600;
    white-space: normal;
    text-align: center;
}

@media (max-width: 280px) {
    .nome-validade h2 {
        font-size: 13px;
    }

    .nome-validade h3 {
        font-size: 11px;
    }

    .validades-extra {
        font-size: 11px;
    }
}

@media (max-width: 319px) {
    .nome-validade h2 {
        max-width: 20ch !important;
    }
}

@media (max-width: 359px) {
    .nome-validade h2 {
        max-width: 22ch;
    }
}
</style>
